export default () => ({
  open: false,

  toggle() {
    if (this.open) {
      return this.close();
    }
    this.open = true;
    if (this.open) {
    }
    document.body.classList.toggle('DropdownMenuOpen', this.open);
  },

  close() {
    if (!this.open) return;
    this.open = false;
  },
});
