// import { Alpine as AlpineType } from 'alpinejs';

export default () => ({
  tooltip: false,

  openTooltip(event: Event) {
    const target = event.target as HTMLElement;
    const tooltip = target.getAttribute('data-fullname');
    // @ts-expect-error
    this.tooltip = tooltip;
  },
  closeTooltip() {
    this.tooltip = false;
  },
});
