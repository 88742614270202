import { Alpine as AlpineType } from 'alpinejs';

export default () => ({
  opened: false,
  lastFocusedItem: null,
  open() {
    this.opened = true;
    // @ts-expect-error
    this.$nextTick(() => {
      // @ts-expect-error
      this.lastFocusedItem = document.activeElement;
      // @ts-expect-error
      if (this.$refs.focusItem) {
        // @ts-expect-error
        this.$nextTick(() => {
          // @ts-expect-error
          this.$refs.focusItem.focus();
        });
      }
    });
  },
  close() {
    this.opened = false;
    if (this.lastFocusedItem) {
      // @ts-expect-error
      this.lastFocusedItem.focus();
    }
  },
});
